const articles = [
  {
    title: 'Long-Awaited Lawnstock Makes First In-Person Return Since 2019',
    author: 'The Western Front',
    link: 'https://www.westernfrontonline.com/article/2022/06/long-awaited-lawnstock-makes-first-in-person-return-since-2019',
  },
  {
    title: 'Battling It Out Until the End',
    author: 'The Western Front',
    link: 'https://www.westernfrontonline.com/article/2020/03/battling-it-out-until-the-end',
  },
  {
    title: 'Hockey Teeth Wins Sounds of the Underground',
    author: 'Wavelength',
    link: 'https://asreview.org/2020/03/09/hockey-teeth-wins-sounds-of-the-underground/',
  },
];

export default articles;
