const config = {
  auth: {
    domain: 'hockeyteeth.us.auth0.com',
    clientId: '1mwH4FXs3RwDQFBv0dR6cTnKj4UWWukd',
  },
  api: {
    apiUrl: 'https://api.hockeyteethband.com',
  },
};

export default config;
